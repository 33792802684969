import axios from "axios";
import queryString from "query-string";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "config/firebase-config";
import env from "helpers/env";

/**
 * useAutoLogin
 *
 * This only works if there is no user already logged in.
 * If we want to always log current users out to auto-login as someone else,
 * this would have to be handled much higher in component tree.
 */
export function useAutoLogin() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { _it: idToken, _ct: customToken } = queryString.parse(
      location.search
    );

    if (!(idToken || customToken)) {
      return;
    }

    if (customToken) {
      loginWithCustomToken(customToken as string).catch(handleError);
    } else if (idToken) {
      loginWithIdToken(idToken as string).catch(handleError);
    }
  }, []);

  function handleError() {
    // eslint-disable-next-line no-console
    console.log("Could not log in automatically.");

    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("_it");
    searchParams.delete("_ct");

    history.replace({ search: searchParams.toString() });
  }
}

async function loginWithIdToken(idToken: string) {
  if (env("WEBPACK_SERVE")) {
    // `true` if doing `yarn start`.
    // eslint-disable-next-line no-console
    console.log("No server available to auto-login with ID token.");
    throw new Error("Cannot use ID Token without server");
  }

  const res = await axios.post("/wrap-token", { idToken });
  await loginWithCustomToken(res.data.token);
}

async function loginWithCustomToken(customToken: string) {
  await firebase.auth().signInWithCustomToken(customToken);
}
